<template>
  <form @submit.prevent="updateUserData" method="POST" v-if="validLink">
          <p class="text-danger" v-if="invalidLogin">Email o contraseña incorrectos</p>
          <div class="form-group">
            <label for="name">Nombre</label>
            <input type="text" class="form-control" id="name" name="name" v-model="form.name" required>
          </div>
          <div class="form-group">
            <label for="dni">DNI</label>
            <input type="text" class="form-control" id="dni" name="dni" v-model="form.dni" required>
          </div>
          <div class="form-group">
            <label for="password">Contraseña</label>
            <input type="password" class="form-control" id="password" name="password" v-model="form.password" required>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block">Actualizar datos</button>
          </div>
        </form>
</template>

<script>
export default {
  data () {
    return {
      validLink: false,
      form: {
        name: null,
        password: null,
        dni: null,
        email: null,
        pHash: null
      }
    }
  },
  methods: {
    updateUserData () {
      this.axios.put('/users', this.form).then((response) => {
        if (response.data && response.data.email === this.form.email) {
          this.$router.push('/login')
        } else {
          alert('Error al actualizar los datos')
        }
      })
    }
  },
  mounted () {
    // Check parameters
    const email = this.$route.query.email
    this.form.email = email
    const pHash = this.$route.query.t
    this.form.pHash = pHash
    this.axios.get('/account-activation', { params: {
      email,
      pHash
    }}).then(response => {
      if (response.data) {
        this.form.name = response.data.name
        this.validLink = true
      } else {
        this.validLink = false
        this.$router.push('/')
      }
    })
  }
}
</script>

<style>

</style>